import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Editor from "./Editor";
import axios from "../../libs/axios";
import Output from "./Output";
import { Img, useMediaQuery } from "@chakra-ui/react";
import crossIcon from "../../assets/Editor/grammarCorrectionCrossIcon.png"
import { motion } from "framer-motion";


const drawerVariants = {
  open: {
    x: 0, // Slide into view
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
  closed: {
    x: "100%", // Slide out of view (to the right)
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
};


const ChatBot = ({setOpen,setPromptsPopupOpen,setInputText,inputText}) => {
  const userEmail = localStorage.getItem("userEmail");

  const [isLoading, setIsLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  // eslint-disable-next-line
  const [userId, setUserId] = useState(null);
  const [isUpdateAPICall, setIsUpdateAPICall] = useState(false);

  const [outputText, setOutputText] = useState([]);

  const createChatId = useCallback(async () => {
    try {
      if (outputText?.length > 0 && !chatId) {
        setIsLoading(true);
        const createChatApiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/gpt4/createGptChat`;
        const body = {
          email: userEmail,
          messages: outputText,
        };

        const { data } = await axios.post(createChatApiURL, body);
        const messageLength = data.data.messages.length;

        setOutputText((prevState) => [
          ...prevState,
          data.data.messages[messageLength - 1],
        ]);

        setChatId(data?.data?._id);
        setUserId(data?.data?.userId);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [outputText, chatId, userEmail]);

  const updateChatData = useCallback(async () => {
    try {
      if (chatId && outputText.length > 0) {
        setIsLoading(true);
        const body = {
          email: userEmail,
          chatId: chatId,
          messages: outputText,
        };
        const updateChatApiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/gpt4/gptchatcompletion/${chatId}`;
        const { data } = await axios.put(updateChatApiURL, body);
        const messageLength = data.data.messages.length;
        setOutputText((prevState) => [
          ...prevState,
          data.data.messages[messageLength - 1],
        ]);
        setIsUpdateAPICall(false);

        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [outputText, chatId, userEmail]); // The empty dependency array means this callback doesn't depend on any props or state

  return (
    // <div className="">
    <motion.div className=" w-[100%] h-0 border-l   relative border-[#DDDDDD]"
    initial="closed" // Start with the drawer closed
    animate={setOpen ? "open" : "closed"} // Animate based on state
    variants={drawerVariants} // Apply the variants
    style={{ zIndex: 999 }}

    >
     <div className="flex justify-between  gap-[180px] px-[10px] items-center   py-[16px] border-l-2  border-[#DDDDDD]  ">
          <div
            className=" px-3 flex
            text-[black]
            text-[19px]
            
            font-[500] font-[Source Sans Pro]"
          >
            Your AI Assistant
          </div>

          <div className="ml-2 px-2 py-0" style={{ cursor: "pointer" }}>
            <Img
              className=""
              style={{
                height: "24px",
                width: "24px",
                marginTop: "2px",
                marginLeft: "10%",
              }}
              src={crossIcon}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
        <div className="absolute  w-[100%]  px-4  h-[91vh] overflow-scroll scrollbar-none z-30  bg-white  font-inter  border-b  border-l-2  border-[#DDDDDD] ">
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Typography paragraph>
          <Output outputText={outputText} isLoading={isLoading} />
        </Typography>

        <Editor
          outputText={outputText}
          setOutputText={setOutputText}
          createChatId={createChatId}
          updateChatData={updateChatData}
          chatId={chatId}
          setIsUpdateAPICall={setIsUpdateAPICall}
          isUpdateAPICall={isUpdateAPICall}
          isLoading={isLoading}
          setPromptsPopupOpen={setPromptsPopupOpen}
          setInputText={setInputText}
          inputText={inputText}
        />
      </Box></div>
    </motion.div>
  );
};

export default ChatBot;
