import { CircularProgress } from "@mui/material";
import React from "react";

import Markdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import atelierCaveDark from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-cave-dark";
import search from "../../assets/advanced-search.png";
import pen from "../../assets/Advanced-Pen.png";
import document from "../../assets/Advanced-Document.png";
// import { useState } from "react";


function Output({ outputText, isLoading }) {

  const isUserInputEmpty = !outputText || outputText.length === 0;



  return (
    <div className=" w-[100%] ">
      <div
        className=" whitespace-pre-wrap w-[100%] justify-center m-auto h-[30rem] overflow-y-auto"
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {/* <h2>AI Output</h2> */}
        {/* <div className="browse-prompts" > */}
        {/* </div> */}

        {isUserInputEmpty && (
          <div className="mt-4 pr-10">
            <div>
              <p className="text-start text-base">
                <span className="font-bold text-xl">Welcome to Your AI Assistant!</span><br /><br />
                Ready to boost your productivity? Our AI tool helps you work smarter, faster, and more creatively. Explore the power of AI by browsing prompts to get started on tasks like:
              </p>
              <div className="lists-container">
                {/* <div className="list-container">
                  <img className="empty-img" src={search} alt="" />
                  <div>
                    <h1 className="empty-sub-head">Find the Perfect Prompt</h1>
                    <p className="empty-para">
                      Browse through our curated prompts to help you kickstart any task, from content creation to idea generation.
                    </p>
                  </div>
                </div> */}
                {/* <div className="list-container">
                  <img className="empty-img" src={pen} alt="" />
                  <div>
                    <h1 className="empty-sub-head">Get Inspired</h1>
                    <p className="empty-para">
                      Let the AI suggest creative ideas and solutions tailored to your needs.
                    </p>
                  </div>
                </div> */}
              </div>
              <p className="text-start text-base mt-1">
                Start exploring prompts now and see how our AI can transform the way you work!
              </p>
            </div>
          </div>
        )}

        {!isUserInputEmpty &&
          outputText?.map((chat) => {
            return (
              <div
                key={chat.id} // Make sure each child in a list has a unique key
                className={`group flex p-4 mb-2 pt-3 pr-5 md:rounded-xl ${chat?.role === "assistant" ? "bg-blue-100" : "#fff"
                  }`}
              >
                <div className="m-1 mr-2 h-6 w-6 md:m-2 md:mr-4 md:h-8 md:w-8 grid place-content-center mt-1">
                  {`${chat?.role === "assistant" ? "A" : "U"}`}
                </div>
                <div className="flex w-full flex-col p-1 pt-2">
                  <div className="grid flex-grow items-center text-green-900">
                    <div className="flex-1 overflow-x-auto pl-1">
                      <div className="markdown leading-relaxed">
                        {chat?.content && (
                          <Markdown
                            children={chat.content ?? ""}
                            components={{
                              code({ className, children, ...rest }) {
                                const match = /language-(\w+)/.exec(
                                  className || ""
                                );
                                return match ? (
                                  <SyntaxHighlighter
                                    {...rest}
                                    children={String(children).replace(
                                      /\n$/,
                                      ""
                                    )}
                                    showLineNumbers
                                    style={atelierCaveDark}
                                    language={match[1]}
                                    PreTag="div"
                                  />
                                ) : (
                                  <code {...rest} className={className}>
                                    {children}
                                  </code>
                                );
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="flex w-full items-center">
                      <div className="flex items-center"> 👍 👎</div>
                      <div className="ml-auto flex items-center text-green-800">
                        Copy
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {isLoading && <CircularProgress />}
      </div>
    </div>
  );
}

export default Output;
