import { useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";

const Delete = ({ id, show, setShow, deleteDocument, fetchDocuments }) => {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setShow(false); // Close the popup when clicking outside
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShow]);

  const deleteDoc = async (e) => {
    e.preventDefault();
    await deleteDocument(id);
    await fetchDocuments();
    setShow(false);
    toast.success("Document deleted successfully");
  };

  return (
    <div
      className={`fixed top-0 left-0 z-20 w-full h-full ${
        show ? "block" : "hidden"
      } bg-[rgba(0,0,0,0.3)] text-white font-roboto`}
    >
      <div
      ref={popupRef}
        className={`m-auto p-4 absolute z-20 min-h-fit xl:w-[31vw] opacity-100 text-center bg-white rounded-lg ${
          isMobile
            ? "transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            : "top-[38%] left-[38%]"
        }`}
      >
        <div className="my-3 text-[#000000] text-sm font-[500] w-[100%]">
          Are you sure you want to delete this document?
        </div>
        <div className="d-flex gap-2 mt-3 pb-1 justify-center items-center bottom-8 right-5 text-xs font-[500]">
          <div
            className="mx-1 py-2 px-3 bg-[#3A3AF4] shadow-md tracking-wide cursor-pointer"
            onClick={deleteDoc}
          >
            DELETE
          </div>
          <div
            className="mx-1 py-2 px-3  text-[#000000] bg-[#F3F4F6] shadow-sm border-[1px]  cursor-pointer"
            onClick={() => setShow(false)}
          >
            CANCEL
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delete;
