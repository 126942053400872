import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { IoIosSend } from "react-icons/io";
import PromptsPopup from "../../components/chatBot/PromptsPopup";
import "./Editor.css"

function Editor({
  inputText,
  setInputText,
  outputText,
  setPromptsPopupOpen,
  setOutputText,
  createChatId,
  updateChatData,
  chatId,
  setIsUpdateAPICall,
  isUpdateAPICall,
  isLoading,
}) {

  const handleAddText = (event) => {
    if (
      !isLoading &&
      ((event.key === "Enter" && !event.shiftKey) || event.type === "click")
    ) {
      event.preventDefault();

      setOutputText((prevState) => [
        ...prevState,
        { role: "user", content: inputText },
      ]);
      if (chatId) {
        setIsUpdateAPICall(true);
      }
      setInputText("");
    }
  };

  const handleBrowsePromptsClick = () => {
    setPromptsPopupOpen(true);
  };

  useEffect(() => {
    if (!chatId && outputText?.length > 0) {
      createChatId();
    } else if (chatId && outputText?.length > 0 && isUpdateAPICall) {
      updateChatData();
    }
  }, [outputText, chatId, createChatId, updateChatData, isUpdateAPICall]);

  return (
    <div className="editor-container  mt-10">
      {/* <div className="browse-prompts" > */}
        <button className="bg-[#3A3AF4] text-[#FFFFFF] px-4 py-2 m-1 rounded-lg " onClick={handleBrowsePromptsClick}>Browse Prompts</button>
      {/* </div> */}
      <div className="flex w-[100%] border-2 border-[#959595] rounded send-message-area">
      
        <div className="send-text-area p-1 w-[100%] outline-none  items-center overflow-y-hidden  flex justify-center">
          
          <input
            className="w-[100%] outline-none  text-lg"
            placeholder="Enter Topic - Email Marketing"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleAddText}
            
          />
        </div>
        
        <div
          onClick={handleAddText}
          disabled={isLoading}
          className={`text-lg mt-auto mb-auto  ${
            isLoading
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer opacity-100"
          }  rounded-[10px]  pl-4 pr-2 items-center   max-h-[200px] h-24px `}
        >
          <div className="">
          <IoIosSend  size={33}/></div>
          {/* <SendIcon fontSize="large" style={{ color: "#3B82F6" }} /> */}
        </div>
      </div>
      
      
      {/* {isPromptsPopupOpen && <PromptsPopup setInputText={setInputText} onClose={() => setPromptsPopupOpen(false)} />} */}
    </div>
  );
}

export default Editor;