import React, { useRef, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import ReportLeft from "./FullReport/ReportLeft";
import ReportRight from "./FullReport/ReportRight";
import { RxCross2 } from "react-icons/rx";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";
// import { plagResultContext } from "./PlagContex/PlagContex";

const PlagiarismReport = () => {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  const [openReport, setOpenReport] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // console.log("plagResult->", plagResult);
  const { plagResult } = location.state || {};
  const componentRef = useRef();

  const pdfContentStyle = {
    // paddingRight: "30px", // Add your desired padding value
  };
  console.log(plagResult ,"plagResultplagResult")
  return (
    
    <div>
      <div className=""></div>
      <div className="flex justify-between" ref={componentRef}>
        <div className="w-[65%]">
          <div className="flex justify-between items-center py-2 px-3 border-b-2 ">
            <RxCross2
              size={35}
              className="font-semibold"
              onClick={() => navigate(-1)}
            />
            <div className="flex justify-end gap-3 pr-2">
              <ReactToPrint
                trigger={() => (
                  <button className="bg-[#3A3AF4] shadow-sm text-[#FFFFFF] px-4 py-2">
                    Download Report
                  </button>
                )}
                content={() => componentRef.current}
                // Provide custom styles to the PDF content
                documentTitle="Plagiarism_Report"
                bodyClass="pdf-content"
                bodyStyle={pdfContentStyle}
              />
              {/* <button className="bg-[#3A3AF4] text-[#FFFFFF] px-3 py-2">Results</button> */}
            </div>
          </div>
          <div className="mx-10 mt-4 ">
            <ReportLeft plagResult={plagResult} setOpenReport={setOpenReport} />
          </div>
        </div>

        <div className="w-[35%] " style={pdfContentStyle}>
          <ReportRight setOpenReport={setOpenReport} plagResult={plagResult} />
        </div>
      </div>
    </div>
  );
};

export default PlagiarismReport;
