import React, { useRef, useEffect, useState } from "react";
import "./PromptsPopup.css";
import closeIcon from "../../assets/close-icon.png";

function PromptsPopup({ onClose, setInputText }) {
  const categories = [
    {
      heading: "Content & SEO",
      subheadings: [
        { title: "Article Generator", content: "Write an article about [topic]. Include relevant statistics (with source links), diverse perspectives, and actionable insights. Write in a [X tone] (e.g., professional, conversational). Ensure it is SEO-optimized with appropriate headers and subheaders." },
        { title: "Blog Outline", content: "Generate a detailed blog outline for [topic]. Include the title, introduction, main sections, subheadings, and conclusion. Suggest keywords for SEO and provide a brief description for each section." },
        { title: "Blog Post Generator", content: "Write a blog post on [topic] aimed at [target audience]. Include relevant keywords, engaging content, and a strong call-to-action. Make it suitable for SEO and include subheadings for readability."},
        { title: "SEO Content Brief", content: "Create an SEO content brief for [topic]. Include target keywords, suggested word count, competitor references, content goals, and tone of voice. Outline any required sections and headers."},
        { title: "SEO Keyword Ideas", content: "Generate a list of SEO keywords for [topic]. Include primary keywords, long-tail keywords, and related phrases. Highlight keyword difficulty and search volume (if possible)."},
        { title: "Headline Generator", content: "Generate 10 engaging headlines for [topic]. Focus on capturing the audience's attention while incorporating target keywords. Provide a mix of listicle, question, and actionable formats."},
        { title: "FAQ Generator", content: "Create a list of frequently asked questions for [topic]. Ensure they are relevant to [industry/audience]. Include concise, helpful answers and structure them for SEO optimization."},
        { title: "Backlink Outreach Email", content: "Write a professional and persuasive backlink outreach email to [recipient/company]. Highlight the value of linking to [content/page]. Include a clear call-to-action and maintain a polite tone."},
        { title: "Short Summary", content: "Summarize [topic/content] in [X words]. Ensure the summary is concise, engaging, and captures the key points. Suitable for meta descriptions or quick overviews."},
        { title: "Product Descriptions", content: "Write a compelling product description for [product]. Include key features, benefits, and a clear call-to-action. Optimize the content for SEO with relevant keywords."},
        { title: "Landing Page Copy", content: "Write conversion-focused copy for a landing page about [product/service]. Include a strong headline, engaging subheadings, benefits, and a clear call-to-action. Optimize for SEO and readability."},
      ],
    },
    {
      heading: "Recruitment & Talent Acquisition",
      subheadings: [
        { title: "Job Posting Template", content: "Use this format to post jobs..." },
        { title: "Personalized Outreach Email", content: "Write engaging outreach emails..." },
        { title: "Interview Questions Generator", content: "Write engaging outreach emails..." },
        { title: "Candidate Evaluation Framework", content: "Write engaging outreach emails..." },
        { title: "Offer Letter Templates", content: "Write engaging outreach emails..." },
        { title: "LinkedIn Outreach Templates", content: "Write engaging outreach emails..." },
        { title: "Recruitment Ad Copy", content: "Write engaging outreach emails..." },
      ],
    },
    {
      heading: "Sales & Lead Generation",
      subheadings: [
        { title: "Cold Email Template", content: "Send cold emails effectively..." },
        { title: "Lead Scoring Framework", content: "Checklist for qualifying leads..." },
        { title: "Sales Pitch Generator", content: "Checklist for qualifying leads..." },
        { title: "Follow-up Email Sequences", content: "Checklist for qualifying leads..." },
        { title: "Call Script Generator", content: "Checklist for qualifying leads..." },
        { title: "Personalized Proposal Templates", content: "Checklist for qualifying leads..." },
        { title: "Client Onboarding Plan", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Social Media Management",
      subheadings: [
        { title: "Social Media Caption Generator", content: "Send cold emails effectively..." },
        { title: "Instagram Hashtag Suggestions", content: "Checklist for qualifying leads..." },
        { title: "LinkedIn Post Templates", content: "Checklist for qualifying leads..." },
        { title: "Twitter Thread Creator", content: "Checklist for qualifying leads..." },
        { title: "TikTok Video Script Ideas", content: "Checklist for qualifying leads..." },
        { title: "Content Calendar Generator", content: "Checklist for qualifying leads..." },
        { title: "Viral Campaign Ideas", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Business Strategy",
      subheadings: [
        { title: "SWOT Analysis Generator", content: "Send cold emails effectively..." },
        { title: "Business Plan Framework", content: "Checklist for qualifying leads..." },
        { title: "Go-to-Market Strategy", content: "Checklist for qualifying leads..." },
        { title: "Customer Persona Builder", content: "Checklist for qualifying leads..." },
        { title: "Competitive Analysis Guide", content: "Checklist for qualifying leads..." },
        { title: "Market Entry Strategy", content: "Checklist for qualifying leads..." },
        { title: "Value Proposition Canvas", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Ideation & Innovation",
      subheadings: [
        { title: "Brainstorming Ideas Generator", content: "Send cold emails effectively..." },
        { title: "Product Launch Checklist", content: "Checklist for qualifying leads..." },
        { title: "Problem-Solution Framework", content: "Checklist for qualifying leads..." },
        { title: "Innovation Process Workflow", content: "Checklist for qualifying leads..." },
        { title: "Blue Ocean Strategy Tools", content: "Checklist for qualifying leads..." },
        { title: "Idea Validation Checklist", content: "Checklist for qualifying leads..." },
        { title: "AI Prompt Writer", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Email Marketing",
      subheadings: [
        { title: "Attention-Interest-Desire-Action (AIDA) Emails", content: "Send cold emails effectively..." },
        { title: "Features-Advantages-Benefits (FAB) Templates", content: "Checklist for qualifying leads..." },
        { title: "Newsletter Topics", content: "Checklist for qualifying leads..." },
        { title: "Drip Campaign Sequences", content: "Checklist for qualifying leads..." },
        { title: "Email Subject Line Ideas", content: "Checklist for qualifying leads..." },
        { title: "Customer Retention Email Templates", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Paid Advertising",
      subheadings: [
        { title: "Facebook Ad Copy Generator", content: "Send cold emails effectively..." },
        { title: "Google Ads Headline Suggestions", content: "Checklist for qualifying leads..." },
        { title: "Instagram Ad Caption Generator", content: "Checklist for qualifying leads..." },
        { title: "YouTube Ad Script Creator", content: "Checklist for qualifying leads..." },
        { title: "Call-to-Action Templates", content: "Checklist for qualifying leads..." },
        { title: "Ad Performance Testing Ideas", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "PR & Communications",
      subheadings: [
        { title: "Press Release Generator", content: "Send cold emails effectively..." },
        { title: "Customer Case Study Framework", content: "Checklist for qualifying leads..." },
        { title: "Internal Memo Templates", content: "Checklist for qualifying leads..." },
        { title: "Media Outreach Email Templates", content: "Checklist for qualifying leads..." },
        { title: "Event Invitation Templates", content: "Checklist for qualifying leads..." },
        { title: "Product Brochure Content", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "E-Learning & Academia",
      subheadings: [
        { title: "Course Outline Generator", content: "Send cold emails effectively..." },
        { title: "Study Material Creator", content: "Checklist for qualifying leads..." },
        { title: "Online Quiz Questions Generator", content: "Checklist for qualifying leads..." },
        { title: "Lesson Plan Templates", content: "Checklist for qualifying leads..." },
        { title: "Assignment Brief Templates", content: "Checklist for qualifying leads..." },
        { title: "Research Proposal Writer", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Blogging & Writing",
      subheadings: [
        { title: "Creative Story Generator", content: "Send cold emails effectively..." },
        { title: "Guest Post Outline", content: "Checklist for qualifying leads..." },
        { title: "Content Rewrite Tools", content: "Checklist for qualifying leads..." },
        { title: "Grammar and Style Check", content: "Checklist for qualifying leads..." },
        { title: "Editing & Proofreading Tips", content: "Checklist for qualifying leads..." },
        { title: "Topic Brainstorming Ideas", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Customer Engagement",
      subheadings: [
        { title: "Personalized FAQ Generator", content: "Checklist for qualifying leads..." },
        { title: "Live Chat Response Templates", content: "Checklist for qualifying leads..." },
        { title: "Client Feedback Email Templates", content: "Checklist for qualifying leads..." },
        { title: "Customer Testimonial Request", content: "Checklist for qualifying leads..." },
        { title: "Loyalty Program Ideas", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Productivity & Workflow",
      subheadings: [
        { title: "Task Prioritization Framework", content: "Checklist for qualifying leads..." },
        { title: "Daily Planner Generator", content: "Checklist for qualifying leads..." },
        { title: "Meeting Agenda Templates", content: "Checklist for qualifying leads..." },
        { title: "Project Milestone Checklist", content: "Checklist for qualifying leads..." },
        { title: "Workflow Automation Suggestions", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Brand Building",
      subheadings: [
        { title: "Brand Story Template", content: "Checklist for qualifying leads..." },
        { title: "Brand Voice Guidelines", content: "Checklist for qualifying leads..." },
        { title: "Tagline and Slogan Creator", content: "Checklist for qualifying leads..." },
        { title: "Mission and Vision Statements", content: "Checklist for qualifying leads..." },
        { title: "Brand Messaging Playbook", content: "Checklist for qualifying leads..." },
      ],
    },
    {
      heading: "Market Research",
      subheadings: [
        { title: "Industry Trends Analysis", content: "Checklist for qualifying leads..." },
        { title: "Customer Behavior Insights", content: "Checklist for qualifying leads..." },
        { title: "Market Size Estimation Framework", content: "Checklist for qualifying leads..." },
        { title: "Competitive Pricing Analysis", content: "Checklist for qualifying leads..." },
        { title: "Demand Forecasting Models", content: "Checklist for qualifying leads..." },
      ],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editorContent, setEditorContent] = useState(""); // Tracks content in the editor

  const popupRef = useRef(null);

  // Close the popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handlePromptClick = (content) => {
    setEditorContent(content); // Update the editor with the selected prompt content
  };

  const handleUsePrompt = () => {
    setInputText(editorContent); // Update the parent with the edited content
    onClose(); // Close the popup
  };
  

  return (
    <div className="popup-overlay">
      <div className="popup-container" ref={popupRef}>
        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </button>
        <h3 className="popup-heading">Prompts</h3>

        <div className="popup-content">
          {/* Categories Section */}
          <div className="categories-section">
            {categories.map((category, index) => (
              <div key={index} className="category">
                <h3
                  className={`category-heading ${
                    selectedCategory === index ? "active" : ""
                  }`}
                  onClick={() =>
                    setSelectedCategory(selectedCategory === index ? null : index)
                  }
                >
                  {category.heading}
                </h3>
                {selectedCategory === index && (
                  <div className="subheadings">
                    {category.subheadings.map((sub, subIndex) => (
                      <button
                        key={subIndex}
                        className="prompt-button"
                        onClick={() => handlePromptClick(sub.content)}
                      >
                        {sub.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Editor Section */}
          <div className="editor-section">
            <h4>Editor</h4>
            <textarea
              value={editorContent}
              onChange={(e) => setEditorContent(e.target.value)} // Reflect user changes
              className="editor-textarea"
              placeholder="Selected prompt content will appear here...                      or make your Custom Prompts..."
            />
            <button className="use-prompt-button" onClick={handleUsePrompt}>
              Use Prompt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromptsPopup;
