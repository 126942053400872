import React from "react";
import "./SectionTwo.css";
import { useNavigate } from "react-router-dom";

function SectionTwo() {
  const navigate = useNavigate();
  return (
    <div className="innerHome-container-bg ">
      <div className="text-home">
      <p>Start for FREE!</p>
      <p>
      no credit card required!
      </p>
      </div>
      
      <div className="buttons" onClick={() => navigate("/new-sign-up")}>
        <button className="home-button1">
          Start for FREE
        </button>
      </div>
    </div>
  );
}


export default SectionTwo;
