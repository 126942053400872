import React from 'react';
import Typewriter from 'typewriter-effect';
import { motion } from 'framer-motion';

const TypewriterPlaceholder = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typewriter
        options={{
          strings: [
            'Focus on your ideas, not on lag – our tool keeps your workflow fluid.',
            'Unlock error-free writing with the ultimate grammar checker.',
            'Start writing your masterpiece...',
          ],
          autoStart: true,
          loop: true,
          delay: 50, // Typing speed
          deleteSpeed: 30, // Speed at which text is deleted
          pauseFor: 1000, // Pause before switching to the next string
        }}
      />
    </motion.div>
  );
};

export default TypewriterPlaceholder;
