import React from "react";
import FullResults from "./FullResults";
import { useMediaQuery } from "@chakra-ui/react";


const ReportRight = ({ setOpenReport, plagResult }) => {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  // const { results } = plagResult;
  // console.log(results)
  const { results } = plagResult; // Declare only once here

  return (
    <div
      className={`${
        !isMobile ? " " : "m-auto mobile-padding"
      } font-inter border-l-2 h-screen `}
    >
      {!isMobile ? (
        <div className="flex relative justify-between  ">
          
          <div className=" text-xl px-4  py-[14px] w-full border-b-2 text-[#6E6E70] font-[600]">
          Results ({results?.internet?.length || 0})
          </div>
          {/* <div>
            <div
              className="mt-3 mr-4 cursor-pointer "
              onClick={() => setOpenReport(false)}
            >
              X
            </div>
          </div> */}
        </div>
      ) : null}

      <FullResults plagResult={plagResult} />

      
    </div>
  );
};

export default ReportRight;
