import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import Report from "./FullReport/Report";
import PlagResults from "./PlagResults";
import PlagiarismReport from "./PlagiarismReport";
import { useNavigate } from "react-router-dom";
import crossIcon from "../../assets/Editor/grammarCorrectionCrossIcon.png";
import { Img } from "@chakra-ui/react";
import { motion } from "framer-motion";
import Tooltip from "@mui/material/Tooltip";

const drawerVariants = {
  open: {
    x: 0, // Slide into view
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
  closed: {
    x: "100%", // Slide out of view (to the right)
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.3,
    },
  },
};

const PlagReport = ({
  report,
  checkPlag,
  plagResult,
  setPlagDrawerOpen,
  isPlagDrawerOpen,
}) => {
  const [openReport, setOpenReport] = useState(false);
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setCheck(report != null ? true : false);
  }, [report]);

  const checkPlagOnClick = async () => {
    await checkPlag();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "100%",

      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      maxHeight: "45rem",
    },
  };

  return (
    <motion.div
      className="w-[100%] h-0 border-l  relative border-[#DDDDDD] "
      initial="closed" // Start with the drawer closed
      animate={setPlagDrawerOpen ? "open" : "closed"} // Animate based on state
      variants={drawerVariants} // Apply the variants
      style={{ zIndex: 999 }}
    >
      <div className="flex justify-between  gap-24 items-center   py-[14px] border-l-2  border-[#DDDDDD]  ">
        <div
          className="pt-2 px-3 flex
            text-[black]
            text-[19px]
            
            font-[500] font-[Source Sans Pro]"
        >
          Plagiarism Report
        </div>

        <div className="ml-2 px-2 py-0" style={{ cursor: "pointer" }}>
          <Img
            className=""
            style={{
              height: "24px",
              width: "24px",
              marginTop: "2px",
              marginLeft: "10%",
            }}
            src={crossIcon}
            onClick={() => {
              setPlagDrawerOpen(false);
            }}
          />
        </div>
      </div>
      <hr />
      <div className=" absolute  top-[60px]  w-[100%] mt-1 px-4 py-2  z-30  bg-white  font-inter  border-b  border-l-2  border-[#DDDDDD] ">
        <Tooltip
          title={report?.internet?.length > 0 ? "" : "No plagiarism report"}
          arrow
          PopperProps={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10], // Change 5 to adjust the vertical distance from the icon
                },
              },
            ],
          }}
          sx={{ background: "none", m: 0, p: 0, position: "top" }}
        >
          <button
            className={`text-[14px] cursor-pointer text-[#3A3AF4] pb-[2px] border-b ${
              report?.internet?.length > 0
                ? ""
                : "opacity-50 cursor-not-allowed"
            }`}
            onClick={() =>
              navigate("/plagiarism-report", {
                state: {
                  plagResult,
                },
              })
            }
            disabled={!report?.internet?.length > 0}
          >
            View Full Plagiarism Report
          </button>
        </Tooltip>

        <div className="h-[84vh] mt-3 overflow-scroll scrollbar-none ">
          {report?.internet?.length > 0 ? (
            report?.internet?.map((f) => {
              return (
                <div className="border-2 border-[#CBCBCB] mt-2 rounded-md ">
                  <PlagResults finding={f} />
                </div>
              );
            })
          ) : (
            <>
              <div className="mt-2 ">
                <div
                  className={`${check ? "hidden" : "block"}
                          mt-4 px-4 py-2
                          bg-[#3b82f6] rounded-lg
                          cursor-pointer
                          text-sm font-[500] text-[#FFFFFF] text-center`}
                  onClick={checkPlagOnClick}
                >
                  Check Plagiarism
                </div>
              </div>
              <div className="text-[#6E6E70] text-sm font-[500] mt-4">
                No Plagiarism Found
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default PlagReport;
