import React from "react";
import iden from "../../../assets/Plagiarism/Ellipse 44.svg";
import minor from "../../../assets/Plagiarism/Ellipse 45.svg";
import para from "../../../assets/Plagiarism/Ellipse 46.svg";
import { useMediaQuery } from "@chakra-ui/react";

const FullResults = ({ plagResult }) => {
  const { results } = plagResult;
  const [isMobile] = useMediaQuery("(max-width: 900px)");

  return (
    <div className="overflow-scroll px-6 scrollbar-none   w-full h-[92vh]">
      {/* {results?.internet?.lenght} */} 
      {results?.internet?.map((data ) => {
        return (
          <div
            key={data?.id}
            className="mt-6 w-[100%] font-inter
                border-2 border-[#767676]/90 rounded-lg
                
                "
          >
            
            <div className="mx-3">
              <div
                className="mt-3 mb-2
                        text-[16px] text-[#000000]
                        font-[600] break-words"
              >
                {data?.title}
              </div>
              <div
                className="
                break-words
                        text-sm text-justify text-[#808080]
                        font-[400] tracking-normal leading-6"
              >
                {data?.introduction}
              </div>
              <div
                className="
                        text-[#006FFF] text-base
                        italic text-ellipsis overflow-hidden whitespace-nowrap w-[100%] cursor-pointer"
              >
                <a href={data?.url} className="no-underline">
                  {data?.url}{" "}
                </a>
              </div>
            </div>
            {/* <hr /> */}
            <div className=" ">
              <div
              className="mx-3 border-t-2 mt-2 pb-3   text-[#F60C0C]"
                // className={`${isMobile ? "mx-2" : "px-3"}
                //   md:text-md  lg:text-lg
                // font-[400] border-t`}
              >
                {data?.similarWords} similar words
              </div>
             
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FullResults;
