import React from "react";
import "./SectionOne.css";
import Img2 from "../../../assets/videos/Frame_33-removebg-preview.png";

// import landingimgone from "../../../assets/Group 33.svg";
import video from "../../../assets/videos/video-animation.mp4";
const SectionOne = () => {
  return (
    <div className="section-one pb-5">
      <div className="ai-container">
        <div className="ai-text">Powered by AI</div>
      </div>
      <div className="your-component">
        Transform Your Writing with
        <br />
        Humanised, Original and
        <br />
        Flawless Content Assistant
      </div>
      <div className="text-container">
        Harness the triple power of advanced grammar checking, plagiarism
        <br />
        detection, and Humanised AI-driven content generation.
      </div>
      <div className="relative my-3 ">
        <div className="">
          <video
            className=" w-[60%] mx-auto  h-auto shadow-lg mt-5"
            autoPlay
            loop
            muted
            style={{ position: "relative", zIndex: 1 }}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="flex justify-center  mx-auto ">
          <img
            src={Img2}
            alt=""
            
            style={{
              position: "absolute",
              top: "-10%",
              width: "10%",
              height: "50%",
              zIndex: 0,
              left: "16%",
            }}
          />
          <img
            src={Img2}
            alt=""
            className="w-[250px] "
            style={{
              position: "absolute",
              top: "60%",
              right: "16%",
              width: "10%",
              height: "50%",
              zIndex: 0,
            }}
          />
          {/*  */}
          {/* absolute left-2 transform -translate-x-1/2 -translate-y-1/4 z-0 */}
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
