import React, { useEffect, useState, useCallback } from "react";
import { Editor } from "primereact/editor";
import "./Editor.css";
import { background, Img } from "@chakra-ui/react";
import BoldIcon from "../../../assets/Editor/BoldIcon.png"
import LinkIcon from "../../../assets/Editor/LinkIcon.png"
import ItalicIcon from "../../../assets/Editor/ItalicIcon.png"
import HeadingIcon from "../../../assets/Editor/HeadingIcon.png"
import UnderLineIcon from "../../../assets/Editor/UnderLineIcon.png"
import TypewriterPlaceholder from "./TypewriterPlaceholder"
import MonacoEditor from "react-monaco-editor";
import ErrorWordPopup from './ErrorWordPopup'; // Import the new component
// import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import Skeleton from "@mui/material/Skeleton";
export default function BasicDemo({
  setSelectedErrorKey,
  selectedErrorKey,
  setIsOpen,
  isOpen,
  results,
  newDocument,
  newSetDocument,
  setTitle,
  editorValue,
  setEditorValue,
  isMobile,
  setLoadingInner,
  editorOptions,
  handleEditorDidMount,
  editorWidth,
  setError,
  modifiedError
}) {
  const editorRef = React.useRef(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [highlightedContent, setHighlightedContent] = useState("");
  const [errorMap, setErrorMap] = useState({});
  const [rawText, setRawText] = useState(""); // Store plain text temporarily

   


  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Set loading to false after a delay
    }, 2000);
    // Customizing button text for H1 and H2
    let H1Button = document.querySelector('.ql-header[value="1"]');
    if (H1Button) {
      H1Button.innerHTML = `<img
            src="${HeadingIcon}"
            style="height: 24px; width: 24px;" alt="H1 Icon" />`;
    }
  }, []);


  const applyFormat = (command, value = "") => {
    const selectedText = window.getSelection().toString();
    if (selectedText.length === 0) return;
    document.execCommand(command, false, value);
  };
  


  const header = (
    <div className={`flex ${isMobile ? "flex-col" : "flex-row"}`}>
      <input
        type="text"
        className={` mb-0   ${isMobile ? "ml-0" : "ml-2"
          } text-xl w-[50%] outline-none text-[black]  font-[600]`}
        placeholder="Untitled Document"
        value={newDocument?.title}
        onChange={(e) => {
          newSetDocument({ ...newDocument, title: e.target.value });
          setTitle(e.target.value);
        }}
      />
      <span
        className={`${isMobile ? "ml-2" : "ml-0"} `}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          height: "51px",
        }}
      >
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-bold"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("bold")}
          >
            <Img style={{ height: "16px", width: "16px" }} src={BoldIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-link"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("createLink")}
          >
            <Img style={{ height: "16px", width: "16px" }} src={LinkIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-italic"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("italic")}
          >
            <Img style={{ height: "16px", width: "16px" }} src={ItalicIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-header"
            value="1"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("formatBlock", "h1")}
          >
            <Img src={HeadingIcon} />
          </button>
        )}
        {loading ? (
          <Skeleton variant="square" width={40} height={40} animation="wave" />
        ) : (
          <button
            className="ql-underline"
            style={{ height: "34px", width: "34px" }}
            onClick={() => applyFormat("underline")}
          >
            <Img
              style={{ height: "16px", width: "16px" }}
              src={UnderLineIcon}
            />
          </button>
        )}
      </span>
    </div>
  );
  //   function stripHTML(text) {
  //     // Allowable tags: <p>, <div>, <br>
  //     const allowedTags = /<\/?(p|div|br)[^>]*>/i;
  //     const spanTags = /<\/?span[^>]*>/gi;

  //     // First, remove all span tags completely
  //     const textWithoutSpans = text.replace(spanTags, "");

  //     // Then remove other unwanted tags while keeping allowed tags
  //     return textWithoutSpans.replace(/<\/?[^>]+>/gi, (tag) =>
  //       allowedTags.test(tag) ? tag : ""
  //     );
  //   }

  //   const highlightErrors = (text) => {
  //     if (!results || results.length === 0) return text;

  //     // Sort errors in reverse order of offset to avoid index conflicts while modifying text
  //     const sortedErrors = [...results].sort((a, b) => b.offset - a.offset);
  //     console.log(sortedErrors, "sortedErrors")

  //     sortedErrors.forEach(({ offset, length, rule }) => {
  //       const partToHighlight = text.substr(offset, length);
  //       console.log(partToHighlight, "parttohighlight")
  //       let highlightedWord = partToHighlight;

  //       // Check if the category is "TYPOS" and apply pink color for the highlight
  //       if (rule.category && rule.category.id === "TYPOS") {
  //         highlightedWord = `<span style="color: orange; text-decoration: underline">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "SPELLING") {
  //         highlightedWord = `<span style="color: purple; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "TYPOGRAPHY") {
  //         highlightedWord = `<span style="color: #0066ff; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "CASING") {
  //         highlightedWord = `<span style="color: #00cc00; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "SEMANTICS") {
  //         highlightedWord = `<span style="color: #ff66b2; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "STYLE") {
  //         highlightedWord = `<span style="color: #996633; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else if (
  //         rule.category &&
  //         rule.category.id === ""
  //       ) {MORFOLOGIK_RULE_EN_US
  //         highlightedWord = `<span style="color: #008080; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "PUNCTUATION") {
  //         highlightedWord = `<span style="color: #FFD700; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "MISC") {
  //         highlightedWord = `<span style="color: blueviolet; text-decoration: underline">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "GRAMMAR") {
  //         highlightedWord = `<span style="color: red; text-decoration: underline">${partToHighlight}</span>`;
  //       } else if (rule.category && rule.category.id === "CONFUSED_WORDS") {
  //         highlightedWord = `<span style="color: #FF7F50; text-decoration: underline;">${partToHighlight}</span>`;
  //       } else {
  //         highlightedWord = `<span style="text-decoration: underline">${partToHighlight}</span>`;
  //       }

  //       text =
  //         text.slice(0, offset) + highlightedWord + text.slice(offset + length);
  //     });
  // const stripedText = stripHTML(text)
  //     return stripedText;
  //   };

  //   useEffect(() => {
  //     if (newDocument?.text || editorValue) {
  //       setHighlightedContent(highlightErrors(newDocument?.text || editorValue));
  //     }
  //   }, [newDocument?.text, editorValue, results]);

  //   useEffect(() => {
  //     const currentText = newDocument?.text || editorValue || "";
  //     const strippedText = stripHTML(currentText);
  //     const highlighted = highlightErrors(strippedText, results);
  //     setHighlightedContent(highlighted);
  //   }, [newDocument.text, editorValue, results]);

  //   const getCurrentContent = useCallback(() => {
  //     const currentText = newDocument?.text || editorValue || "";
  //     const strippedText = stripHTML(currentText);
  //     return highlightErrors(strippedText, results);
  //   }, [newDocument?.text, editorValue, results]);

  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    // Temporary fallback: show plain text before response
    const updateHighlightedContent = () => {
      const rawText = newDocument?.text || editorValue || "";
      const strippedText = stripHTML(rawText);
      setRawText(strippedText); // Set raw text for editor
      const highlighted = highlightErrors(strippedText, results);
      setHighlightedContent(highlighted); // Apply highlighting once we get response
    };
    updateHighlightedContent();
  }, [newDocument?.text, editorValue, results,selectedErrorKey]);

  const stripHTML = (text) => {
    const allowedTags = /<\/?(p|div|br)[^>]*>/i;
    return text.replace(/<\/?[^>]+>/gi, (tag) =>
      allowedTags.test(tag) ? tag : ""
    );
  };

  const sanitizePastedContent = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Remove inline styles like `font-family`
    tempElement.querySelectorAll("[style]").forEach((el) => {
      el.removeAttribute("style");
    });

    // Remove unwanted tags like <script>, <style>
    const unwantedTags = ["script", "style"];
    unwantedTags.forEach((tag) => {
      tempElement.querySelectorAll(tag).forEach((el) => el.remove());
    });

    // Remove specific classes
    tempElement.querySelectorAll("[class]").forEach((el) => {
      el.removeAttribute("class");
    });

    // Allow only certain tags and unwrap unwanted ones
    const allowedTags = /<\/?(p|div|br|span)[^>]*>/i;
    tempElement.innerHTML = tempElement.innerHTML.replace(/<\/?[^>]+>/gi, (tag) =>
      allowedTags.test(tag) ? tag : ""
    );


    // Return sanitized HTML
    return tempElement.innerHTML;
  };


  const highlightErrors = (text, results = []) => {
    if (!text || !results || results.length === 0) return text;
    let highlightedText = text;
    const newErrorMap = {};
    const sortedErrors = [...results].sort((a, b) => b.offset - a.offset);

    sortedErrors.forEach((error, index) => {
      const { offset, length, rule } = error;
      const partToHighlight = highlightedText.substr(offset, length);
      const color = getColorByCategory(rule.category?.id);
      
           // Create a unique key for each error
      const errorKey = `error-${index}`;

      // Store error details in the map
      newErrorMap[errorKey] = error;

      // Wrap the error word with a unique identifier
      const highlightedWord = `<span data-error-key="${errorKey}" style="color: ${color}; text-decoration: underline; cursor: pointer; background-color: ${selectedErrorKey === errorKey ? 'rgba(255, 255, 0, 0.3)' : 'transparent'} ">${partToHighlight}</span>`;

      highlightedText =
        highlightedText.slice(0, offset) +
        highlightedWord +
        highlightedText.slice(offset + length).replace(/(<\/span>);/g, '$1');
    });

    // Update the error map
    setErrorMap(newErrorMap);
    return highlightedText;
  };
  const getColorByCategory = (category) => {
    const colors = {
      TYPOS: "orange",
      SPELLING: "purple",
      TYPOGRAPHY: "#0066ff",
      CONFUSED_WORDS: "#FF7F50",
      SEMANTICS: "#ff66b2",
      STYLE: "#996633",
      MORFOLOGIK_RULE_EN_US: "#008080",
      PUNCTUATION: "#FFD700",
      MISC: "blueviolet",
      GRAMMAR: "red",
    };
    return colors[category] || "green";
  };

  const handleTextChange = (e) => {
    const rawText = stripHTML(e?.htmlValue || "");
    setEditorValue(rawText); // Update editor value without HTML tags
    setLoadingInner(true);
    // Optionally, update rawText here to avoid unnecessary re-rendering
  };

  const handleEditorClick = (e) => {
    const errorSpan = e.target.closest('[data-error-key]');

    if (errorSpan) {
      const errorKey = errorSpan.getAttribute('data-error-key');
      const error = errorMap[errorKey];
       // Toggle the selected error key
       setSelectedErrorKey(prevKey => prevKey === errorKey ? errorKey : errorKey);
      if (error) {
        setError(error)
        setIsOpen(true)
      }else{
        setSelectedErrorKey(null);
       
      }
    }
  };

  return (
    <>

      <div>
        {/* Toolbar with full-width */}
        <div style={{ width: "100%" }}>{header}</div>

        {/* Centered content editor */}
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div
            style={{
              width: isMobile ? "100%" : "100%",
            }}
          >
            {/* <Editor
                            ref={editorRef}
                            // value={text}
                            value={newDocument?.title}
                        
                            onTextChange={(e) => {
                                const newTitle = e.htmlValue; // Use textValue to avoid HTML tags
                                newSetDocument({ ...newDocument, title: newTitle });
                                setTitle(newTitle);
                            }}
                            // onTextChange={(e) => {setText(e.htmlValue); }}
                            headerTemplate={null} // Hide the default header in the Editor
                            style={{
                                height: '100vh',
                            
                            }}
                        /> */}

            {loading ? (
              <>
                {/* You can also add a few small lines inside the skeleton like loading text lines */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Skeleton width="100%" height={30} animation="wave" />
                  <Skeleton width="100%" height={60} animation="wave" />
                  <Skeleton width="100%" height={120} animation="wave" />
                  <Skeleton width="100%" height={40} animation="wave" />
                  <Skeleton width="100%" height={60} animation="wave" />
                  <Skeleton width="100%" height={30} animation="wave" />
                  <Skeleton width="100%" height={60} animation="wave" />
                  <Skeleton width="100%" height={120} animation="wave" />
                </div>
              </>
            ) : (
              <div style={{ position: "relative" }}>
                {/* Placeholder animation */}
                {!(editorValue || newDocument?.text) && (
                  <div
                    style={{
                      // marginLeft: "11%",
                      marginTop: "0.5%",
                      position: "absolute",
                      top: "10px", // Adjust to fit your needs
                      left: "1.8%", // Adjust to fit your needs
                      pointerEvents: "none", // Allow typing without affecting the placeholder
                      color: "#888",
                      fontSize: "16px",
                    }}
                  >
                    <TypewriterPlaceholder />
                  </div>
                )}

                {/* <Editor
                                    ref={editorRef}
                                    style={{
                                        width: "100%", height: '70.5vh'
                                    
                                    }}
                                    headerTemplate={null}
                                    value={getCurrentContent()||highlightErrors(stripHtml(newDocument?.text) || editorValue)}
                                    // value={highlightErrors(stripHtml(newDocument?.text) || editorValue)}
                                    // value={newDocument?.text || editorValue}
                                    onTextChange={(e) => {
                                        const content = stripHtml(e.htmlValue);
                                        setEditorValue(content);
                                        setLoadingInner(true);
                                        // highlightErrors(content)
                                    }}
                                    // className="w-[250px] sm:w-[350px] md:w-[450px]  lg:w-[600px]  xl:w-[700px] 2xl:w-[900px]  "
                                    key={forceUpdate}
                                    /> */}

                {/* <Editor
                style={{
                  width: "100%",
                  height: "70.5vh",
                }}
                // modules={modules}
                // formats={formats}
                // value={highlightErrors(newDocument?.text || editorValue)}
                value={highlightedContent}
                onTextChange={(e) => {
                  const htmlValue = (e?.htmlValue || "").trim(); // Ensure a string before calling trim()

                  // Split content into paragraphs by newlines or double line breaks
                  const paragraphs = htmlValue
                    .split(/<\/p>\s*<p>/) // Handles Quill's paragraph output
                    .map((para) => para.replace(/<\/?p>/g, "").trim()); // Remove existing <p> tags

                  // Wrap each paragraph in <p> tags and enclose everything in a <div>
                  const wrappedContent = `<div>${paragraphs
                    .map((para) => `<p>${para}</p>`)
                    .join("")}</div>`;

                  setEditorValue(wrappedContent);
                  setLoadingInner(true);
                  console.log("Stored Content:", wrappedContent);
                }}
                key={forceUpdate}
              /> */}
                <div
                  ref={editorRef}
                  className="text-lg  caret-gray-700 outline-none px-3border-b-2 mt-2 scrollbar-thin"
                  dangerouslySetInnerHTML={{ __html: highlightedContent || rawText }}
                  style={{ width: "100%", overflow: "auto", height: "75vh" }}
                  contentEditable
                  suppressContentEditableWarning
                  onClick={handleEditorClick}
                  onInput={(e) => {
                    const htmlValue = e.currentTarget.innerHTML;
                    setEditorValue(htmlValue);
                    handleTextChange({ htmlValue });
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    const pastedContent =
                      (e.clipboardData || window.Clipboard).getData("text/html") ||
                      (e.clipboardData || window.Clipboard).getData("text/plain");

                    const sanitizedContent = sanitizePastedContent(pastedContent).replace(/&nbsp;/g, " ");
                    document.execCommand("insertHTML", false, sanitizedContent);
                  }}

                />


              </div>

              // <Editor
              //     ref={editorRef}
              //     style={{width: "100%", height: '70.5vh', marginLeft: "10%",// Adjust margin when menu is open
              // }}
              //     headerTemplate={null}
              //     placeholder="Type here..."
              //     value={stripHtml(newDocument?.text) || editorValue}
              //     onTextChange={(e) => {
              //         const content = stripHtml(e.htmlValue);
              //         setEditorValue(content);
              //         setLoadingInner(true)
              //     }}
              //     className="w-[250px] sm:w-[350px] md:w-[450px]  lg:w-[600px]  xl:w-[700px] 2xl:w-[900px] " />
            )}
          </div>
        </div>
      </div>

    </>
  );
}

