import React from "react";
import { Pie } from "react-chartjs-2";
import { useMediaQuery } from "@chakra-ui/react";
import "./Chart.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

const Charts = ({ plagResult }) => {
  const [isMobile] = useMediaQuery("(max-width: 900px)");
  let identicalWords = plagResult?.results?.score?.identicalWords;
  let minorChangedWords = plagResult?.results?.score?.minorChangedWords;
  let relatedMeaningWords = plagResult?.results?.score?.relatedMeaningWords;

  const chartData = [identicalWords, minorChangedWords, relatedMeaningWords];

  return (
    <div className={`${!isMobile ? "flex overflow-hidden" : "block"} plag_board `}>
      <div className={`m-auto py-4 plag_chart`}>
        <Pie
          data={{
            datasets: [
              {
                label: "",
                data: chartData,
                backgroundColor: ["#FDD09F", "#A9F4D0", "#9A89FF", ],
              },
            ],
          }}
        />
      </div>

      <div className="plag_content">
        <p>
          <svg width="60" height="20" viewBox="0 0 30 30" fill="none">
            <rect x="0" y="0" width="60" height="40" fill="#FDD09F" />
          </svg>
          Identical{" "}
          <span className="">
            ({plagResult?.results?.score?.identicalWords})
          </span>
        </p>
        
        <p>
        <svg width="60" height="20" viewBox="0 0 30 30" fill="none">
            <rect x="0" y="0" width="60" height="40" fill="#A9F4D0" />
          </svg>
          Minor Chages{" "}
          <span className="">
            
            (${plagResult?.results?.score?.minorChangedWords})
          </span>
        </p>
        <p>
          {" "}
          <svg width="60" height="20" viewBox="0 0 30 30" fill="none">
            <rect x="0" y="0" width="60" height="40" fill="#9A89FF" />
          </svg>
          Paraphrased{" "}
          <span className="">
           ({plagResult?.results?.score?.relatedMeaningWords})
          </span>
        </p>
      </div>
    </div>
  );
};

export default Charts;

// <p>
//   {" "}
//   <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
//     <circle cx="15" cy="15" r="15" fill="#FF222233"  />
//   </svg>
//   Omitted Words <span>40.0%</span>
// </p>
