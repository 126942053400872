import React, { useCallback, useEffect, useState } from "react";
import iden from "../../assets/Plagiarism/Ellipse 44.svg";
import minor from "../../assets/Plagiarism/Ellipse 45.svg";
import para from "../../assets/Plagiarism/Ellipse 46.svg";
import omit from "../../assets/Plagiarism/Ellipse 47.png";


const PlagResults = ({ finding }) => {
  const [data, setData] = useState({
    text: finding.introduction,
    url: finding.url,
  });

  const setLevel = useCallback(() => {
    if (finding == null) return;
    
    if (finding.matchedWords > 80) {
      setData({
        category: "Identical",
        description: "Text is copied and pasted exactly as it is",
        text: finding.introduction,
        image: iden,
        color: "#f78000",
        url: finding.url,
      });
    } else if (finding.matchedWords > 50) {
      setData({
        category: "Minor Changes",
        description: `Text is copied and pasted with minor changes`,
        text: finding.introduction,
        image: minor,
        color: "#2A9D8F",
        url: finding.url,
      });
    } else if (finding.matchedWords > 30) {
      setData({
        category: "Paraphrased",
        description: "Text is copied, parapharsed and then pasted",
        text: finding.introduction,
        image: para,
        color: "#E9C46A",
        url: finding.url,
      });
    } else {
      setData({
        category: "Omitted Words",
        description: "Text is copied and pasted without few words",
        text: finding.introduction,
        image: omit,
        color: "#FFD7D5",
        url: finding.url,
      });
    }
  }, [finding]);

  useEffect(() => {
    // console.log("finding", finding);
    if (finding == null) return;
    setLevel();
  }, [finding, setLevel]);



  if (data == null) return <div></div>;

  return (
    <div className="p-3">
      <div className="  flex gap-2 items-center">
        <img className=" h-2" src={data.image} alt="bullet" />
        <div className="text-[14px] text-[#5E5E5E]">{data.category}</div>
      </div>
      <span className="text-[14px] text-[#000000]" >
        {data.text}
        {/* style={{ background: data.color }} */}
       
        
      </span>
      <a
        className="  font-semibold text-[12px]  box-content border-b border-[#3A3AF4]  "
        href={data.url}
        target="_blank"
        rel="noreferrer"
      >
        <span className="text-[#3A3AF4]">visit source</span>
      </a>
    </div>
  );
};

export default PlagResults;

// <div className="">
// <div className="">
// <img className=" h-2" src={data.image} alt="bullet" />
// <div className="text-[14px]">{data.category}</div>
// </div>
// {/* <div className="my-2 text-[14px] text-[#6E6E70] font-[600]">
//     {data.description}
//   </div> */}
// <p className="" >
//   <div className=" text-[14px] bg-white">
//     <PlagResultsText Text={Text} />
//   </div>
{
  /* {data.text} */
}
//   </p>

//   <a
//     className="  font-semibold text-[12px] border-b border-[#3A3AF4]  "
//     href={data.url}
//     target="_blank"
//     rel="noreferrer"
//   >
//     <span className="text-[#3A3AF4]">visit source</span>
//   </a>
// </div>
