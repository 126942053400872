import React, { useState, useEffect, useContext } from "react";
import Header from "../Header";
import Footer from "../Footer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import greenTick from "../../../../assets/Group 9204.svg";
import greyTick from "../../../../assets/Group 9205.svg";
import { Link } from "react-router-dom";
import axios from "../../../../libs/axios";
import "./SignUp.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { provider, auth } from "../../../../firebase.js";
import { signInWithPopup } from "firebase/auth";
import { useAuth } from "../../../context/auth.js";
import { UserDispatchContext, useUpgradeModal } from "../../../context/user.js";
import { getDaysDiff } from "../../../../libs/utility.js";
import CircularProgress from "@mui/material/CircularProgress";

const passwordValidations = [
  {
    label: "8 characters least",
    check: (password) => password.length >= 8,
  },
  {
    label: "Contains one alphabet",
    check: (password) => /[a-zA-Z]/.test(password),
  },
  {
    label: "Contains uppercase",
    check: (password) => /[A-Z]/.test(password),
  },
  {
    label: "Contains number",
    check: (password) => /\d/.test(password),
  },
  {
    label: "Contains lowercase",
    check: (password) => /[a-z]/.test(password),
  },
  {
    label: "Special characters",
    check: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
  },
];

const SignUp = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [allValidationsPassed, setAllValidationsPassed] = useState(false);
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [showOtpBtn, setShowOtpBtn] = useState(true);
  const [showOtpField, setShowOtpField] = useState(false);
  const [sentOtp, setSentOtp] = useState(null);
  const [otp, setOtp] = useState(null);
  const [otpVerify, setOtpVerify] = useState(false);
  const [loading, setLoading] = useState(false);

  const [timer, setTimer] = useState(30); // Timer countdown
  const [showResendButton, setShowResendButton] = useState(true);

  const { setIsAuthenticated } = useAuth();
  const { setshowupgrade } = useUpgradeModal();
  const setUser = useContext(UserDispatchContext);

  const startCountdown = (setTimer, setShowResendButton) => {
    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setShowResendButton(true); // Show resend button after timer ends
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  useEffect(() => {
    // Check if all password validations pass
    const allValid = passwordValidations.every((validation) =>
      validation.check(password)
    );
    setAllValidationsPassed(allValid);
  }, [password]);

  const getUser = (isLoggedIn) => {
    if (isLoggedIn) {
      axios.get("/api/user").then((res) => {
        if (res.status === 200) {
          // setUserDetails(res.data.data)
          if (res.data.data) {
            setUser(res.data.data);
            const daysDiff = getDaysDiff(
              new Date(res.data.data?.createdAt),
              new Date()
            );
            const daysLeft = 15 - daysDiff;

            daysLeft > 0 && res.data.data?.subscription == null
              ? console.log(`${daysLeft} days free trial left`)
              : res.data.data?.subscription == null
              ? setshowupgrade(true)
              : new Date(res.data.data?.subscription.endDate) >= new Date()
              ? console.log("Subscribed")
              : console.log("Subscription Expired");
          }
        }
      });
    }
  };

  const loginCalling = async (data) => {
    try {
      const loginUserURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/newauth/login`;
      const body = {
        email: data?.data?.email,
        password: password,
      };

      try {
        const { data } = await axios.post(loginUserURL, body);
        if (data?.success) {
          getUser(true);
          if (data.signInData.token) {
            setIsAuthenticated(true);
          }
          localStorage.setItem("token", data.signInData?.token);
          localStorage.setItem("userEmail", data.signInData?.email);
          localStorage.setItem("emailVerified", data.signInData?.emailVerified);
          localStorage.setItem("expiresIn", data.signInData?.expiresIn);
          localStorage.setItem("isAnonymous", data.signInData?.isAnonymous);
          localStorage.setItem("operationType", data.signInData?.operationType);
          localStorage.setItem("refreshToken", data.signInData?.refreshToken);
          localStorage.setItem("registered", data.signInData?.registered);
          navigate("/afterLogin");
        }
      } catch (error) {
        console.error("Error during login request:", error);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleNextClick = async () => {
    if (allValidationsPassed && fullName && email && otpVerify === true) {
      try {
        const registeredUserURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/newauth/init`;
        const body = {
          email: email,
          fullname: fullName,
          password: password,
        };
        const { data } = await axios.post(registeredUserURL, body);
        if (data?.success) {
          loginCalling(data);
        } else if (!data?.success) {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error(error);
      }
      // Your logic here
    } else {
      toast.warning("Please fill all details");
    }
  };

  const handleEmailInput = (e) => {
    const regex = /^[a-zA-Z0-9@._-]*$/;
    e.target.value = e.target.value.replace(/^\s+/g, "").match(regex)
      ? e.target.value
      : email; // Revert to the last valid state if invalid
  };

  // send otp functionality
  const handleOtpSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    // setIsFirebaseUser(false); // Adjust state as required

    try {
      const res = await axios.post("/api/auth/init", {
        withCredentials: true,
        email,
      });

      if (res.status === 200) {
        setSentOtp(true);
        setLoading(false);
        setTimer(30);
        setShowResendButton(false); // Hide the resend button
        toast.success("OTP sent");
        setOtpVerify(false);
        setShowOtpField(true);
        // Start the timer countdown
        setShowOtpBtn(false);
        startCountdown(setTimer, setShowResendButton);
        return true;
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "OTP sending failed");
      setSentOtp(false);
      setLoading(false);
      setShowOtpField(false);
      return false;
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/auth/resend", { email });

      if (response.status === 200) {
        // setSendOtp(true)
        toast.success("OTP resent");

        setShowResendButton(false); // Hide the resend button
        setTimer(30); // Reset the timer

        // Start the timer countdown
        startCountdown(setTimer, setShowResendButton);

        return response.data; // Return the success message
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to resend OTP");
      return false; // Indicate failure
    }
  };
  const name = document.getElementById("fullName");
  const passwordField = document.getElementById("password");
  // const otherFields = document.querySelectorAll(".other-field");
  const otpVerifyHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    name.removeAttribute("required");
    passwordField.removeAttribute("required");

    try {
      if (email && otp && sentOtp) {
        const res = await axios.post("/api/auth/verify", {
          email,
          otp,
          flag: "signup",
        });
        if (res.status === 200) {
          setOtpVerify(true);
          setOtp(null);
          setLoading(false);
          setShowOtpField(false);
          toast.success("Authenticated Successfully!");
          return true;
        } else {
          toast.error("Authentication failed");
          setLoading(false);
          setShowOtpField(true);
          return false;
        }
      }
    } catch (error) {
      // console.log(error);
      toast.error("Something went wrong");
      setLoading(false);
      return false;
    }
  };

  const loginWithGoogle = async () => {
    try {
      const googleLoginData = await signInWithPopup(auth, provider);
      if (googleLoginData.user) {
        const loginUserURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/newauth/login/google`;

        const body = {
          email: googleLoginData?.user?.email,
          uid: googleLoginData?.user?.uid,
          accessToken: googleLoginData?.user?.accessToken,
          userData: googleLoginData?.user?.reloadUserInfo,
          fullname: googleLoginData?.user?.displayName,
        };

        try {
          const { data } = await axios.post(loginUserURL, body);
          if (data?.success) {
            getUser(true);
            if (data.signInData.token) {
              setIsAuthenticated(true);
            }
            localStorage.setItem("token", data.signInData?.token);
            localStorage.setItem("userEmail", googleLoginData?.user?.email);
            localStorage.setItem(
              "emailVerified",
              googleLoginData?.user?.emailVerified
            );
            // localStorage.setItem("expiresIn", data.signInData?.expiresIn);
            localStorage.setItem(
              "isAnonymous",
              googleLoginData?.user?.isAnonymous
            );
            localStorage.setItem(
              "operationType",
              data.signInData?.operationType
            );
            // localStorage.setItem("refreshToken", data.signInData?.refreshToken);
            // localStorage.setItem("registered", data.signInData?.registered);
            navigate("/afterLogin");
          }
        } catch (error) {
          console.error("Error during login request:", error);
        }
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <>
      <Header />
      <div className="sign-up-container">
        <form className="sign-up-form">
          <div className="sign-up-header" onClick={loginWithGoogle}>
            Start your free Oyster trial
          </div>
          {/* <div className="sign-up-google" onClick={loginWithGoogle}  >Sign up with Google</div> */}
          <button
            type="button"
            class="login-with-google-btn"
            onClick={loginWithGoogle}
          >
            Sign up with Google
          </button>
          <div className="or">-OR-</div>

          <div className="form-label">
            <InputLabel
              sx={{
                color: "#7C838A",
                // marginBottom: "8px",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
              htmlFor="fullName"
            >
              Full Name
            </InputLabel>
            <TextField
              sx={{
                width: "100%",
                height: "100%",
                background: "rgba(176, 186, 195, 0.40)",
                borderRadius: "12px",
              }}
              id="fullName"
              fullWidth
              margin="normal"
              required
              className="other-field"
              placeholder="Enter your Full Name here"
              onChange={(e) => setFullName(e.target.value)}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^a-zA-Z\s]/g, "")
                  .replace(/^\s+/g, "");
              }}
            />
          </div>

          <div className="form-label">
            <InputLabel
              sx={{
                color: "#7C838A",
                marginBottom: "8px",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
              htmlFor="workEmail"
            >
              Work Email
            </InputLabel>
            <div className="flex  gap-3 justify-between">
              <div className={` ${showOtpBtn == false ? "w-full" : "w-full "}`}>
                <TextField
                  sx={{
                    width: "100%",
                    height: "100%",
                    // height: "10vh",
                    margin: 0,
                    background: "rgba(176, 186, 195, 0.40)",
                    borderRadius: "12px",
                  }}
                  type="email"
                  className="other-field"
                  id="fullName"
                  fullWidth
                  margin="normal"
                  required
                  placeholder="Enter your Email here"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setShowOtpBtn(true);
                    // setOtpVerify(false);
                    // setSentOtp(false);
                  }}
                />
              </div>
              {showOtpBtn === true ? (
                <div
                  className="w-[200px] text-center "
                  style={{
                    backgroundColor: "#3B82F6",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "100px", // Add height to center content vertically
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "#ffffff", // Set custom color
                      }}
                      onClick={(e) => e.preventDefault()}
                    />
                  ) : (
                    <button
                      className="text-white h-full px-2"
                      onClick={handleOtpSend}
                    >
                      Send OTP
                    </button>
                  )}
                </div>
              ) : null}

              {/* <button
                className="text-white hover:bg-[#2a6ad3] px-2 w-[200px] "
                onClick={handleOtpSend}
                style={{ backgroundColor: "#3B82F6", borderRadius: "10px" }} // Correct style syntax
              >
                {sendOtp === true ? <input type="text" className="w-full outline-none" placeholder="Enter otp"/> : <span>Send OTP</span>}
                
              </button> */}
            </div>
          </div>
          {showOtpField === true ? (
            <div className="  flex pb-3 pt-1 gap-2 w-full justify-between">
              <input
                type="text"
                className="otp w-full outline-none border rounded-xl border-[#e5e7eb]  h-full "
                placeholder="Enter otp"
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
              <div className="flex  w-[200px] gap-2">
                <div
                  className="text-white "
                  style={{
                    backgroundColor: "#3B82F6",
                    borderRadius: "8px",
                    width: "50%",
                  }}
                >
                  {!showResendButton ? (
                    <span className="flex items-center justify-center mt-1">
                      Wait {timer}
                    </span>
                  ) : (
                    <button className="h-full" onClick={handleResendOtp}>
                      Resend
                    </button>
                  )}
                </div>
                {/* <button
                  onClick={otpVerifyHandler}
                  className="px-[10px] w-full text-white py-1"
                  style={{ backgroundColor: "#3B82F6", borderRadius: "8px" }}
                >
                  Verify
                </button> */}
                <div
                  className=" text-center  w-[50%]"
                  style={{
                    backgroundColor: "#3B82F6",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "100px", // Add height to center content vertically
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "#ffffff", // Set custom color
                      }}
                      onClick={(e) => e.preventDefault()}
                    />
                  ) : (
                    <button
                      className="px-[10px]  text-white py-1 cursor-pointer"
                      onClick={otpVerifyHandler}
                      disabled={!otp}
                    >
                      Verify
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div className="form-label">
            <InputLabel
              sx={{
                color: "#7C838A",
                // marginBottom: "8px",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
              }}
              htmlFor="password"
            >
              Password
            </InputLabel>
            <TextField
              sx={{
                width: "100%",
                height: "100%",
                background: "rgba(176, 186, 195, 0.40)",
                borderRadius: "12px",
                color: "rgba(0, 0, 0, 0.50)",
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: "400",
              }}
              id="password"
              type="password"
              fullWidth
              margin="normal"
              required
              placeholder="Create your Password here"
              onChange={handlePasswordChange}
            />
            <div className="password-validations">
              {passwordValidations.map((validation, index) => (
                <div key={index} className="validation-item">
                  {validation.check(password) ? (
                    <img src={greenTick} alt="" />
                  ) : (
                    <img src={greyTick} alt="" />
                  )}
                  <span>{validation.label}</span>
                </div>
              ))}
            </div>
          </div>

          <div>
            <Button
              sx={{
                width: "100%",
                background: "#6B6B6B",
                borderRadius: "8px",
                color: "white",
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: "400",
              }}
              variant="contained"
              color="primary"
              className="Next-button"
              onClick={handleNextClick}
              disabled={!allValidationsPassed}
            >
              sign up
            </Button>
          </div>
          <div className="login-section">
            <span className="login-text">Already a Member? </span>
            <Link to="/auth" className="login-link">
              LOG IN
            </Link>{" "}
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default SignUp;
