import React from "react";

const PlagScore = ({ score, desc }) => {
  return (
    <div>
      <div
        className="py-3  flex relative w-[100%]
                        text-center text-2xl font-bold 
                        rounded-lg border-[#CBCBCB]  border-2
                        "
      >
        <div className="m-auto p-[3px] text-[#000000] ">
          {score}
          <div className="text-sm text-[#000000] font-[500] mt-1">{desc}</div>
        </div>
      </div>
    </div>
  );
};

export default PlagScore;
