import React from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import logout from "../../../../../assets/Logout.svg";
import "./LogoutConfirmation.css"; // Import your CSS file

const LogoutConfirmation = ({ onConfirm }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      html: `
        <div class="">
          Logout
          
        </div>
        <div>
        <hr class="" /></div>
        <div class="">
          Are you sure you want to log out?
        </div>
      `,
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, Logout",
      reverseButtons: true,
      customClass: {
        popup: "", // Apply custom CSS class to the popup
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
        navigate("/auth");
      }
    });
  };

  return (
    <div>
      <div className="flex  justify-between itme-start" onClick={handleLogout}>
        <div className="flex pl-2 gap-2 items-center">
          <img src={logout} alt="icon" />
          <div className="text-xl font-semibold text-[#3b82f6]" >Logout</div>
        </div>
      </div>
    </div>
  );
};

export default LogoutConfirmation;
